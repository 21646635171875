import React from "react"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import { Container, Row } from "react-bootstrap"

import BlogCard from "../BlogCard"

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allBloggerPost

    return (
      <Container>
        <Row>
          {posts &&
            posts.map(({ node: post }) => (
              <BlogCard key={post.id} post={post} />
            ))}
        </Row>
      </Container>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allBloggerPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allBloggerPost(sort: { fields: published, order: DESC }) {
          edges {
            node {
              slug
              kind
              id
              blog {
                id
              }
              published(
                formatString: "dddd, DD [de] MMMM [de] YYYY"
                locale: "pt"
              )
              updated
              url
              selfLink
              title
              content
              labels
              featuredImage {
                childImageSharp {
                  fluid(
                    maxWidth: 1200
                    maxHeight: 800
                    fit: COVER
                    cropFocus: CENTER
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              author {
                id
                displayName
                url
                image {
                  url
                }
              }
              replies {
                totalItems
                selfLink
              }
              images {
                url
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
