import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import BlogRoll from "../../components/BlogRoll"

const Blog = () => (
  <Layout>
    <SEO title="Blog" />
    <BlogRoll />
  </Layout>
)

export default Blog
