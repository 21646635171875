import React from "react"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import { Card, Badge, Col } from "react-bootstrap"
import Img from "gatsby-image"

const BlogCard = ({ post }) => (
  <Col lg="6" className="my-3" key={post.id}>
    <Link to={`/${post.slug}`} className="text-decoration-none">
      <Card className="shadow rounded-lg h-100 bg-vinho bd-vinho-8">
        <Card.Img
          variant="top"
          as={Img}
          fluid={post.featuredImage.childImageSharp.fluid}
          fadeIn
          durationFadeIn={5000}
          alt="Card image"
        />

        <Card.Body>
          <Card.Title className="cl-fundo">{post.title}</Card.Title>

          {post.labels.map(label => (
            <Badge
              className="mt-2 mr-2 bg-marinho cl-fundo"
              key={label}
              as={Link}
              to={`/tags/${kebabCase(label)}/`}
            >
              {label}
            </Badge>
          ))}
        </Card.Body>
      </Card>
    </Link>
  </Col>
)

export default BlogCard
